import React, { useState, useEffect } from "react";
import Lottie from 'react-lottie-player'
import ViewDocumentsLottie from '../../json/viewDocuments.json'
import { Modal, Button, Loader, Placeholder, Segment } from 'semantic-ui-react';
import { json, useNavigate, useSearchParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation } from "react-i18next";
import axios from "axios";
import Spinner from "../components/Spinner/Spinner";
import 'react-toastify/dist/ReactToastify.css';

export default function ViewDocuments(props) {
  const [showEsignDocument, setEsignDocument] = useState(false);
  const [eSignData, setESignData] = useState({});
  const [isLoading, setLoader] = useState(true);
  const [iframe, setiframeLoader] = useState(true);
  const [isMounted, setIsMounted] = useState(false);

  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsMounted(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);
  localStorage.removeItem("base64Data")
  useEffect(() => {
    setLoader(false);
  }, []);

  const viewEsignDocument = async () => {
    setEsignDocument(true);
  };

  const handleViewDocumentClick = async () => {
    viewEsignDocument();
  };

  const previewFile = () => {
    const link = document.createElement('a');
    link.href = sessionStorage.getItem('eSignDocumentURL'); // Replace with your own AWS S3 bucket URL
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
  };

  const continueToMoveIn = () => {
    localStorage.setItem("eSignatureCompleted", true)
    navigate('/preBooking/MoveIn')
  }
  const documentUrl =async ()=>{
    setLoader(true);
    const currentsession = JSON.parse(sessionStorage.getItem('tenantInfo'));
    console.log("currentsession", currentsession);
    const requestBody = {
      "event_type": "UPDATE_ESIGN_STATUS",
      "country_code": "NOR",
      "tenant_id": currentsession.userId,
      "tenant_type":  JSON.parse(sessionStorage.getItem('isBussinessUser')) ? "BUSINESS" : "PERSON",
      "initiated_by": currentsession.firstName,
      "document_id": sessionStorage.getItem('bankIdDocumentId'),
      "status": "SUCCESS"
    };
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}`
      },
    };
    const eSignUrl = process.env.REACT_APP_ESIGN_URL;

    try {
      const response = await axios.post(`${eSignUrl}?value=${sessionStorage.getItem('accessToken')}`, requestBody);
      // if(response.data.message === 'DOCUMENT_NOT_READY_TO_DOWNLOAD'){
      //   toast.success(`${t('Document is processing')}`, {
      //     position: 'top-right',
      //     autoClose: 3000,
      //     duration: 100,
      //     className: 'bg-toast-success toast-success',
      //     hideProgressBar: true,
      //     closeOnClick: true,
      //     draggable: true,
      //     progress: undefined,
      //     theme: 'colored',
      // });
      // setLoader(false);
      // return 
      // }
      console.log("eSignCompletionREsponse:-", JSON.stringify(response.data.body));
      setESignData(response.data.body);
      setiframeLoader(false)
      sessionStorage.setItem("eSignDocumentURL", response.data.body.document_url);
      setLoader(false);
      sessionStorage.setItem("moveinCount", true)
    } catch (err) {
      console.log(err);
      setLoader(false);
    }
  }
const loadEsign=async()=>{
    setLoader(true)
      if (!JSON.parse(sessionStorage.getItem("moveinCount"))) {
        sessionStorage.setItem("moveinCount", true)
        documentUrl()
      }
      setLoader(false)
}
  // useEffect(() => {
  //   setLoader(true)
  //   setTimeout(()=>{
  //     loadEsign()
  //   },5000)
   
  // }, [])

    return (<>
     <ToastContainer /> {
        isLoading ?
            <Spinner />
            :
            (<>
                {showEsignDocument ?
                    <div className="iframe-container">
                      {iframe ?
                      <div height="100vh" width="100%">
                      <Spinner />
                       </div>:<div>
                       <iframe height="100vh" width="100%" src={sessionStorage.getItem('eSignDocumentURL')} title="ESigned Data"></iframe>
                        <div className="d-flex justify-content-center mb-2 mt-2">
                          <Button className="ui button text-black close-btn fs-7 fw-400 text-dark px-5 mr-2" onClick={() => setEsignDocument(false)}>{t("Close")}</Button><Button className="ui button fs-7 fw-400 text-white px-5 mr-2 download-btn" onClick={() => previewFile()}>{t("Preview to Download")}</Button></div>
                         </div>

                      }
                      
                       
                    </div> :
                    <div className="bg-white border-radius-15 text-center card-boxshadow border-radius-15 border-top-success-4 w-35 mx-auto px-2 py-5 mt-5">
                        <div className="success-img text-center mb-2">
                            <Lottie
                                loop
                                animationData={ViewDocumentsLottie}
                                play
                                style={{ width: 250, height: 200, margin: '0 auto' }}
                            />
                        </div>
                        <p className="mb-2">{t("Great! You've Successfully signed the documents")}</p>
                        <div className="text-center">
                            <button className="ui button bg-success-dark text-white fw-100 mr-2 mb-1 d-none" onClick={() => handleViewDocumentClick()} >{t("View Documents")}</button>
                            <button className="ui button bg-success text-white fw-100 mb-1" onClick={() => continueToMoveIn()}>{t("Continue")}</button>
                        </div>
                    </div>
                }</>)}
    </>)
}


