const requests = {
    common_config: "v1/common/config",
    facility_cities:"v1/unit/location",
    //Unit Filters
    unit_filters: "v2/unit/filters?UnitVisibility=1&Availability=2",
    user_login:"v1/user/login",
    forgot_password:"v1/user/password/forgot",
    user_signup :"v1/user/signup",
    user_search: "v1/unit/type/price",
    lease_search: "v1/movein/lease",
    get_user_info: "v1/user",
    update_user_info: "v1/user/updateprofile",
    update_user_password: "v1/user/password",
    unit_info_by_id :"v1/movein/lease/calculation",
    promo_discount : "v1/unit/discount",
    validate_promocode: "v1/unit/discount/promocode",
    check_addons: "v1/moveIn/rent/rentDetails",
    tenant_details :"v1/user",
    lease_documents : "/v1/movein/lease/documents/",
    save_vehicledetail:"/v1/movein/vehicleDetails/",
    custom_Fields: "v2/common/custom-fields",
    move_out: "v1/moveout/schedulemoveout/",
    cancel_move_out: "v1/moveout/cancel/",
    user_payment_form:"/v1/user/paymentform/",
    user_credit_card_details:"/v1/cards/details/",
    user_ach_card_details:"/v1/cards/ach-details/",
    card_make_default:"/v1/cards/makedefault/",
    delete_card:"/v1/cards/delete/",
    //Add Card Form
    add_card_form: '/v1/cards/add/form/',
    add_profile_picture: '/v1/user/profile/picture',
    lease_agreement: "v1/moveIn/preview",
    movein_paymentform: "v1/moveIn/paymentform",
    lease_profile:"v1/moveIn/leaseProfile",
    lease_profiles:"v1/moveIn/leaseProfiles",
    save_move:"v1/moveIn",
    invoices: "v1/unit/invoice",
    invoicePaymentForm: "v1/user/paymentform",
    invoicePaymentFormSuccess: "v1/unit/payment",
    getsitedetail:"v1/common/site/details",
    autopayactivate:"v1/cards/autopay/activate/",
    autopaydeactivate:"v1/cards/autopay/deactivate/"
}

export default requests; 