import { t } from 'i18next';
import React, { Component, useState, useEffect, useCallback, contextObject } from 'react';
import { Accordion, Item, Popup, Grid, Segment, Placeholder } from 'semantic-ui-react'
import Helper from "../../helper";
import instance from '../../services/instance';
import request from '../../services/request';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';

const helper = new Helper();
let unitTypeFilters;
let unitypearr = [];
let unitTypesarr;
let locationId;

let storagetypes;

const AccordionExampleStyled = (props) => {
 let selectedStorageType = props.storageTypeValue;
  const [loader, setLoading] = useState(props.loader || true)
  const filters = props.constructFilterValues || JSON.parse(localStorage.getItem('Units'));
  const [filterBuilding, setFilterBuiding] = useState([]);
  const [filterUnitType, setFilterUnitType] = useState([]);
  const [filterDimensions, setFilterDimensions] = useState([]);
  const [filterAmenities, setFilterAmenities] = useState([]);
  const [PriceRangeArrayvalue, setPriceRangeArrayvalue] = useState();
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectAll, setSelectAll] = useState(true);
  const [minPriceState, setMinPriceState] = useState();
  const [maxPriceState, setMaxPriceState] = useState();
  const [PriceRangeStatus, setPriceRangeStatus] = useState(true);
  const { t, i18n } = useTranslation();
  // const selectedBuildingIds = [];
  const [selectedBuildingIds, setSelectedBuildingIds] = useState([]);
  const [selectedUnitTypes, setSelectedUnitTypes] = useState('');
  const [buildingFilter, setBuildingFilter] = useState([]);
  const [unitTypeFilter, setUnitTypeFilter] = useState([]);
  const [unitTypeDimension, setUnitTypeDimension] = useState({});
  const [unitTypeAmenities, setUnitTypeAmenities] = useState([]);
  const [selectAmenities, setselectAmenties] = useState([]);
  const [unitTypeNameState, setUnitTypeName] = useState([]);
  const [originalDimensions, setOriginalDimensions] = useState([]);
  const [unitTypeArr, setUnitTypeArray] = useState([]);
  const [newPriceValue, setNewPriceValue] = useState({
    minPrice: 0,
    maxPrice: 0,
  });
  const [amenityFilter, setAmenityFilter] = useState([]);
  // New Stated for filters
  const [selectedBuilding, setSelectedBuilding] = useState(null);
  const navigate = useNavigate()

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index
    setActiveIndex(newIndex);
  }

  const pricerangeOnchangevalue = (data) => {
    setPriceRangeArrayvalue(data);
  }

  useEffect(() => {
    fetchData();
    locationId = localStorage.getItem('locationid');
    if(locationId === null){
      navigate('/')
    }
  }, [selectedBuildingIds, props.storageTypeValue]);

  const fetchData = ()=> {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };
    const buildingIdsParam = selectedBuildingIds.length > 0 ? `&BuildingId=${selectedBuildingIds.join(",")}` : "";
    const cacheKey = `unitFilters-${locationId}-${buildingIdsParam}`;

    try {
      let response = localStorage.getItem(cacheKey);
      if (!response) {
        response = instance.get(
          request.unit_filters +
          `&LocationId=${locationId}${buildingIdsParam}`,
          config
        );
        localStorage.setItem(cacheKey, JSON.stringify(response));
      } else {
        response = JSON.parse(response);
      }

      if(typeof response !=="undefined" && response !==null && response !==""){
        if(typeof response.data !=="undefined" && response.data !=="" && response.data !==null){
          if(typeof response.data.result !=="undefined" && response.data.result !==null && response.data.result !==""){
            let datas = response.data.result;
            if(typeof props.storageTypeValue !=="undefined" && props.storageTypeValue !==null && props.storageTypeValue !==""){
              datas.forEach((item)=>{
                if(item.storageTypeId === props.storageTypeValue){
                       storagetypes = item.unitTypes;
                }
              });
            }
             // const storageTypeFilter = response.data.result.map((i) => i.storageTypeId === selectedStorageType);
              if (typeof datas !=="undefined" && datas !==null && datas.length > 0){
                unitTypeFilters = storagetypes;
                const unitTypesarr = Object.values(unitTypeFilter.reduce((acc, cur) => Object.assign(acc, { [cur.unitTypeName]: cur }), {}));
                const result = unitTypeFilter.reduce((r, a) => {
                  r[a.unitTypeName] = r[a.unitTypeName] || [];
                  r[a.unitTypeName].push(a);
                  return r;
                }, Object.create(null));
                setUnitTypeFilter(unitTypesarr);
                setUnitTypeDimension(result);
                Object.keys(result).forEach(unitType => {
                  const amenities = result[unitType].map(unit => unit.amenities).flat();
                  const amenityFilter = amenities.filter(i => i && i.storageTypeId === props.storageTypeValue);
                  const uniqueAmenities = amenityFilter.filter((item, index, self) => index === self.findIndex(t => t.name === item.name));
                  setUnitTypeAmenities(uniqueAmenities);
                });
      
              } else {
                console.log("No unit types found for selected storage type");
              }
      
            
          }

        }

      }

    } catch (error) {
      console.error(error);
    }
  };

  function checkStoragePriceRange(PriceRangeArray, storageCategoryValue) {
    let PriceArray = [];
    PriceRangeArray.map((array) => {
      if (array.storageTypeId === storageCategoryValue) {
        PriceArray.push({ storageTypeId: array.storageTypeId, MinPrice: array.MinPrice, MaxPrice: array.MaxPrice })
      }
    });

    if (typeof PriceArray !== "undefined" && PriceArray !== null && PriceArray !== "" && PriceArray.length > 0) {
      let minvalue = Math.min.apply(Math, PriceArray.map(function (o) { return o.MinPrice; }));

      let maxValue = Math.max.apply(Math, PriceArray.map(function (o) { return o.MaxPrice; }));
      setNewPriceValue({
        minPrice: minvalue,
        maxPrice: maxValue
      });

      if (maxValue === minvalue) {
        let priceRangeHiding = document.getElementById("pricerange-hide");
        if (typeof priceRangeHiding !== "undefined" && priceRangeHiding !== null && priceRangeHiding !== "") {
          priceRangeHiding.style.display = 'none';
        }
      } else {
        let priceRangeHiding = document.getElementById("pricerange-hide");
        if (typeof priceRangeHiding !== "undefined" && priceRangeHiding !== null && priceRangeHiding !== "") {
          priceRangeHiding.style.display = 'block';
        }
      }
      sessionStorage.setItem("MinValue", minvalue);
      sessionStorage.setItem("MaxValue", maxValue);
    }
  }

  useEffect(() => {
    if(typeof filterUnitType !=="undefined" && filterUnitType !=="" && filterUnitType !==null && filterUnitType.length === 0){
      sessionStorage.removeItem("UnitTypeId");
    }

    if (
      typeof selectedStorageType !== 'undefined' &&
      selectedStorageType !== null
    ) {
      setLoading(false); // Set loading to false if selectedStorageType has a value
      // Building filter
      if (
        typeof filters !== 'undefined' &&
        filters !== null &&
        filters !== '' &&
        typeof filters.building !== 'undefined' &&
        filters.building !== null &&
        filters.building !== "" &&
        filters.building.length > 0
      ) {
        const buildingFilter = filters.building.filter(i => i.storageTypeId === selectedStorageType);
        setBuildingFilter(buildingFilter);
      }

      // Unit Type filter
      if (
        typeof filters !== 'undefined' &&
        filters !== null &&
        filters !== '' &&
        typeof filters.unitType !== 'undefined' &&
        filters.unitType !== null &&
        filters.unitType !== "" &&
        filters.unitType.length > 0
      ) {
        const unitTypeFilter = filters.unitType.filter(i => i.storageTypeId === selectedStorageType);

        unitTypesarr = (Object.values(unitTypeFilter.reduce((acc, cur) => Object.assign(acc, { [cur.unitTypeName]: cur }), {})));

        let result = unitTypeFilter.reduce(function (r, a) {
          r[a.unitTypeName] = r[a.unitTypeName] || [];
          r[a.unitTypeName].push(a);
          return r;
        }, Object.create(null));
        setUnitTypeFilter(unitTypesarr);
        setUnitTypeDimension(result);
      }

      // Price Range filter
      if (
        typeof filters !== 'undefined' &&
        filters !== null &&
        filters !== '' &&
        typeof filters.priceRangeValue !== 'undefined' &&
        filters.priceRangeValue !== null &&
        filters.priceRangeValue !== "" &&
        filters.priceRangeValue.length > 0
      ) {
        const priceRangeValue = checkStoragePriceRange(filters.priceRangeValue, selectedStorageType);
        setNewPriceValue(priceRangeValue);
      }

      // Amenity filter
      if (
        typeof filters !== 'undefined' &&
        filters !== null &&
        filters !== '' &&
        typeof filters.amenityValue !== 'undefined' &&
        filters.amenityValue !== null &&
        filters.amenityValue !== "" &&
        filters.amenityValue.length > 0
      ) {
        const amenityFilter = filters.amenityValue.filter(i => i.storageTypeId === selectedStorageType);
        const uniqueAmenities = amenityFilter.filter((item, index, self) => index === self.findIndex(t => t.name === item.name));
        setUnitTypeAmenities(uniqueAmenities);
      }
    } else {
      setLoading(true); // Set loading to true if selectedStorageType has no value
    }
  }, [props]);

  useEffect(()=>{
    clearAllFilters(); 
  },[props.storageTypeValue])

  useEffect(() => {
  }, [buildingFilter, unitTypeFilter]);

  const storageTypeOptions = typeof filters !== 'undefined' && filters !== null && filters !== '' && typeof filters.storageType !== 'undefined' && filters.storageType !== null && filters.storageType !== "" && filters.storageType.length > 0 ?
    filters.storageType.map(storageType => {
      return {
        key: storageType.storageTypeId,
        text: storageType.storageTypeName,
        value: storageType.storageTypeId
      }
    }) : '';

  const selectAllBuilding = () => {
    setFilterBuiding([]);
    buildingFilter.forEach((item) => {
      setFilterBuiding((items) => [...items, { buildingname: item.buildingName, buildingid: item.buildingId }]);
    });
  }

  const selectAllUnitType = () => {
    setFilterUnitType([]);
    setUnitTypeName([]);
    if(typeof filters.unitType !=="undefined" && filters.unitType !=="" && filters.unitType !==null && filters.unitType.length > 0){
      filters.unitType.forEach((item) => {
        setFilterUnitType((items) => [...items, { unitTypename: item.unitTypeName, unitTypeid: item.unitTypeId, unitMeasurement: item.unitMeasurement, measurementType: item.measurementType }]);
      });
    }
    
    // unitTypesarr.forEach((item) => {
    //   setUnitTypeName((items) => [...items, { unitTypeName: item.unitTypeName, unitTypeId: item.unitTypeId }]);
    // });

  }

  const selectAllDimension = () => {
    setFilterDimensions([]);
    filterUnitType.forEach((dimension) => {
      setFilterDimensions((items) => [...items, { unitTypeId: dimension.unitTypeid, unitMeasurement: dimension.unitMeasurement, unitypeName: dimension.measurementType }]);
    });
  };

  const selectAllAmenityCheckbox = () => {
    setselectAmenties([]);
    unitTypeAmenities.forEach((item) => {
      setselectAmenties((items) => [...items, { id: item.id, amenitiesName: item.name }]);
    });
  }


  const clearAllBuilding = () => {
    setFilterBuiding([]);
  }

  const clearAllUnitType = () => {
    setUnitTypeName([]);
    setFilterUnitType([]);

  }

  const clearAllDimension = () => {
    setFilterDimensions([]);
  }

  const clearAllAmenityCheckbox = () => {
    setselectAmenties([]);
  }

  const onChangeBuilding = useCallback((e, buildingname, buildingid) => {
    if (e.target.checked) {
      setFilterBuiding([...filterBuilding, { buildingname, buildingid }]);
      setSelectedBuildingIds(prevIds => [...prevIds, buildingid]);
    } else {
      setFilterBuiding(filterBuilding.filter(b => b.buildingid !== buildingid));
      setSelectedBuildingIds(prevIds => prevIds.filter(id => id !== buildingid));
    }
  }, [filterBuilding]);

  const onChangeUnitType = (e, unitTypeName, unitTypeId, unitMeasurement, unitmesurementname) => {
    if (e.target.checked) {
      let unitypedimensions = [];
      if(typeof filters.unitType !=="undefined" && filters.unitType !=="" && filters.unitType !==null & filters.unitType.length > 0){
        filters.unitType.map((item) => {
          if (item.unitTypeName === unitTypeName) {
            unitypedimensions = ([...unitypedimensions, { unitTypename: item.unitTypeName, unitTypeid: item.unitTypeId, unitMeasurement: item.unitMeasurement, unitmesurementname: item.measurementType }]);
          }
          setFilterUnitType([...filterUnitType, ...unitypedimensions]);
        })
      }

      setUnitTypeName([...unitTypeNameState, { unitTypeName: unitTypeName, unitTypeId: unitTypeId }]);
    } else {
      setFilterUnitType((item) => item.filter((i) => i.unitTypename !== unitTypeName));
      setUnitTypeName((item) => item.filter((i) => i.unitTypeId !== unitTypeId));
      const objWithIdIndex = filterUnitType.findIndex((obj) => obj.unitTypeid === unitTypeId);
      if (objWithIdIndex > -1) {
        filterUnitType.splice(objWithIdIndex, 1);
      }

    }
  }



  // const onChangeUnitMesurement = (e, unitMeasurement, unitypeName, unitTypeId) => {
  //   if (e.target.checked) {
  //     setFilterDimensions([...filterDimensions, { unitTypeId: unitTypeId, unitMeasurement: unitMeasurement, unitypeName: unitypeName}]);
  //   } else {
  //    setFilterDimensions((item) => item.filter((i) => i.unitTypeId !== unitTypeId));
  //     const objWithIdIndex = filterDimensions.findIndex((obj) => obj.unitTypeid === unitTypeId);
  //     if (objWithIdIndex > -1) {
  //       filterDimensions.splice(objWithIdIndex, 1);
  //     }
  //     let index = unitypearr.findIndex((obj) => obj.unitTypeid === unitTypeId);
  //     if (index > -1) {
  //       unitypearr.splice(index, 1);
  //     }
  //     if (typeof unitypearr !== "undefined" && unitypearr !== null && unitypearr !== "" && unitypearr.length === 0) {
  //       setFilterUnitType([]);
  //       setFilterDimensions([]);
  //     }
  //   }
  // };

  const sixStorageOnChangeAmenity = (e, amenitiesId, amenitiesName) => {
    if (e.target.checked === true) {
      setselectAmenties([...selectAmenities, { id: amenitiesId, amenitiesName: amenitiesName }]);
    } else {
      setselectAmenties((item) => item.filter((i) => i.id !== amenitiesId));
      setselectAmenties((item) => item.filter((i) => i.id !== amenitiesId));
    }
  }

  // const dimensions = unitTypeFilter.reduce((acc, curr) => {
  //   if (selectedUnitTypes.includes(curr.unitTypeName)) {
  //     const unitTypeDimensions = unitTypeDimension[curr.unitTypeName];
  //     acc.push(...unitTypeDimensions.map((dim) => ({
  //       ...dim,
  //       unitTypeName: curr.unitTypeName,
  //       unitTypeId: curr.unitTypeId,
  //     })));
  //   }
  //   return acc;
  // }, []);



  useEffect(() => {
    // Prepopulate filterDimensions with selected dimensions from filters

    if (typeof filters !== "undefined" && filters !== null && filters !== "") {
      let Amenitieslist = filters.amenityValue;
      setUnitTypeAmenities(Amenitieslist);
    }

    if (filters && filters.length > 0) {
      const selectedDimensions = filters.filter((item) => item.dimension !== undefined);
      setFilterDimensions(selectedDimensions);
    }
  }, [filters]);


  const removeBuilding = (buildingid) => {
    setFilterBuiding((item) => item.filter((i) => i.buildingid !== buildingid));
  }
  const removeUnitType = (unitTypeName, unitTypeId) => {
    setFilterUnitType((item) => item.filter((i) => i.unitTypename !== unitTypeName));
    setUnitTypeName((item) => item.filter((i) => i.unitTypeId !== unitTypeId));
      const objWithIdIndex = filterUnitType.findIndex((obj) => obj.unitTypeid === unitTypeId);
      if (objWithIdIndex > -1) {
        filterUnitType.splice(objWithIdIndex, 1);
      }
   
  }

  const removeUnitDimension = (unitTypeId) => {
    setFilterDimensions((item) => item.filter((i) => i.unitTypeId !== unitTypeId));
    const objWithIdIndex = filterDimensions.findIndex((obj) => obj.unitTypeId === unitTypeId);
    if (objWithIdIndex > -1) {
      filterDimensions.splice(objWithIdIndex, 1);
    }
  }

  const removeAmenities = (amenitiesId) => {
    let removecheckbox = document.querySelectorAll('.amenties-all-checkbox');
    removecheckbox.forEach((item) => {
      if (item.value === amenitiesId) {
        item.checked = false;
      }
    });

    setselectAmenties((item) => item.filter((i) => i.id !== amenitiesId));
  }


  const clearAllFilters = () => {
    setFilterBuiding([]);
    setFilterUnitType([]);
    setFilterDimensions([]);
    setselectAmenties([]);
    setPriceRangeArrayvalue([]);
    setUnitTypeName([]);
    setMaxPriceState(0);
    setMinPriceState(0);
   
  }

  const applyAllFiterValues = () => {
    let selectedbuildingId;
    let selectedUnitTypeId = [];
    let selectedAmenitiesId = [];
    let selectedDimension = [];
    let pagenumbers = 1;

    if (typeof filterUnitType !== "undefined" && filterUnitType !== "" && filterUnitType !== null) {
      selectedUnitTypeId = filterUnitType.filter((i) => i.unitTypeid).map((item) => {
        return item.unitTypeid;
      });
    }

    sessionStorage.setItem("UnitTypeId", JSON.stringify(selectedUnitTypeId));
    
    if (typeof filterBuilding !== "undefined" && filterBuilding !== "" && filterBuilding !== null) {
      selectedbuildingId = filterBuilding.filter((i) => i.buildingid).map((item) => {
        return item.buildingid;
      });
    }

   
    // if (typeof filterDimensions !== "undefined" && filterDimensions !== "" && filterDimensions !== null) {
    //   selectedUnitTypeId = filterDimensions.filter((i) => i.unitTypeId).map((item) => {
    //     return item.unitTypeId;
    //   });
    // }

    if (typeof selectAmenities !== "undefined" && selectAmenities !== "" && selectAmenities !== null) {
      selectedAmenitiesId = selectAmenities.filter((i) => i.id).map((item) => {
        return item.id;
      });
    }


    // if (filterUnitType.length === 0) {
    //   updatedFilterUnitType = filters.unitType;
    // }
    let FilterSearchId = {
      buildingid: selectedbuildingId,
      unitTypeid: selectedUnitTypeId,
      amenitiesid: selectedAmenitiesId,
      priceRange: newPriceValue,
      pagenumbers:pagenumbers
    };

    props.unitsearchFilters(FilterSearchId);

  }

  if (loader) {
    return (
      <Grid className='px-1' columns={1} stackable>
        <Grid.Column key="column1">
          <Segment raised>
            <Placeholder>
              <Placeholder.Paragraph>
                <Placeholder.Line length='medium' />
                <Placeholder.Line length='short' />
                <Placeholder.Line length='medium' />
                <Placeholder.Line length='short' />
              </Placeholder.Paragraph>
              <Placeholder.Paragraph>
                <Placeholder.Line length='medium' />
                <Placeholder.Line length='short' />
                <Placeholder.Line length='medium' />
                <Placeholder.Line length='short' />
              </Placeholder.Paragraph>
              <Placeholder.Paragraph>
                <Placeholder.Line length='medium' />
                <Placeholder.Line length='short' />
                <Placeholder.Line length='medium' />
                <Placeholder.Line length='short' />
              </Placeholder.Paragraph>
            </Placeholder>
          </Segment>
        </Grid.Column>
      </Grid>

    );
  }


  return (
    <Accordion styled key="myAccordionKey">
      <Accordion.Title active={activeIndex === 0} index={0}>
        <div className='d-flex justify-content-between align-items-center'>
          <div className='d-flex justify-content-between align-items-center'>
            <img src="/assets/images/filter.png" alt="" />{t("Filters")}
          </div>
          <div>
            <p className='fs-8 text-success' onClick={() => clearAllFilters()}>{t("Clear All")}</p>
          </div>
        </div>
        <div className='row mt-3 selectedFilter'>

          {typeof filterBuilding !== "undefined" && filterBuilding !== null && filterBuilding !== "" && filterBuilding.length > 0 ?
            filterBuilding.map((item) => {
              return <div key={item.buildingid} className='col-lg-4 col-md-6 col-sm-4'>
                <p key={item.buildingid} className='fs-8 d-flex justify-content-between align-items-center p-1 mr-1 mb-1'>{item.buildingname}<a onClick={() => removeBuilding(item.buildingid)}><img src='/assets/images/wrong.svg' alt='Close' /></a></p>
              </div>
            }) : ""}


          {typeof unitTypeNameState !== "undefined" && unitTypeNameState !== null && unitTypeNameState !== "" && unitTypeNameState.length > 0 ?
            unitTypeNameState.map((item) => {
              return <div key={item.unitTypeId} className='col-lg-4 col-md-6 col-sm-4'>
                <p key={item.unitTypeId} className='fs-8 d-flex justify-content-between align-items-center p-1 mr-1 mb-1'>{item.unitTypeName}<a onClick={() => removeUnitType(item.unitTypeName, item.unitTypeId)}><img src='/assets/images/wrong.svg' alt='Close' /></a></p>
              </div>

            }) : ""}

          {typeof filterDimensions !== "undefined" && filterDimensions !== null && filterDimensions !== "" && filterDimensions.length > 0 ?
            filterDimensions.map((item) => {
              return <div key={item.unitTypeId} className='col-lg-4 col-md-6 col-sm-4'>
                <p key={item.unitTypeId} className='fs-8 d-flex justify-content-between align-items-center p-1 mr-1 mb-1'>{item.unitMeasurement} {item.unitypeName}<a onClick={() => removeUnitDimension(item.unitTypeId)}><img src='/assets/images/wrong.svg' alt='Close' /></a></p>
              </div>
            }) : ""
          }

          {typeof selectAmenities !== "undefined" && selectAmenities !== "" && selectAmenities !== null && selectAmenities.length > 0 ?
            selectAmenities.map((item) => {
              return <div key={item.id} className='col-lg-4 col-md-6 col-sm-4'>
                <p key={item.id} className='fs-8 d-flex justify-content-between align-items-center p-1 mr-1 mb-1'>{item.amenitiesName}<a onClick={() => removeAmenities(item.id)}><img src='/assets/images/wrong.svg' alt='Close' /></a></p>
              </div>
            }) : ""

          }
        </div>
      </Accordion.Title>

      <Accordion.Title className='d-flex justify-content-between align-items-center'
        active={activeIndex === 1}
        index={1}
        onClick={handleClick}
      >
        <div className='d-flex justify-content-between align-items-center'>
          <img src="/assets/images/building.png" alt="" />{t("Building")}
        </div>
        <div>
          <img src="/assets/images/arrow-down.png" alt="" />
        </div>
      </Accordion.Title>
      <Accordion.Content active={activeIndex === 1}>
        <div>
          {/* <h6 className='fw-600 mb-1'>Building</h6> */}
          <div className='text-success text-right'>
            {selectAll ? <a onClick={() => selectAllBuilding()} >{t("Select All")}</a> : ""} | <a onClick={() => clearAllBuilding()}>{t("Clear All")}</a>
          </div>
          <ul>
            {buildingFilter.length > 0 && buildingFilter.map(buildingVal => {
              return (
                <li key={buildingVal.key}>
                  <input
                    key={buildingVal.key}
                    value={buildingVal.buildingId}
                    id={buildingVal.buildingId}
                    className='mr-1 mb-1'
                    type="checkbox"
                    checked={filterBuilding.find((item) => item.buildingid === buildingVal.buildingId)}
                    onChange={(e) => onChangeBuilding(e, buildingVal.buildingName, buildingVal.buildingId)}
                  />
                 <label>{buildingVal.buildingName}</label>
                </li>
              )
            })}
          </ul>
          {typeof buildingFilter !== 'undefined' && buildingFilter !== null && buildingFilter !== '' && buildingFilter.length > 5 ? (
            <a className='text-success text-right d-none' href='/'>{t("MORE")}</a>
          ) : ''}
        </div>
      </Accordion.Content>
      <Accordion.Title className='d-flex justify-content-between align-items-center'
        active={activeIndex === 2}
        index={2}
        onClick={handleClick}
      >
        <div className='d-flex justify-content-between align-items-center'>
          <img src="/assets/images/unit-type.png" alt="" />{t("Unit Type")}
        </div>
        <div>
          <img src="/assets/images/arrow-down.png" alt="" />
        </div>
      </Accordion.Title>
      <Accordion.Content active={activeIndex === 2}>
        <div>
          {/* <h6 className='fw-600 mb-1'>LARGE</h6> */}
          <div className='text-success text-right'>
            {selectAll ? <a onClick={() => selectAllUnitType()}>{t("Select All")}</a> : ""}  | <a onClick={() => clearAllUnitType()}>{t("Clear All")}</a>
          </div>
          <ul>
            {Array.isArray(unitTypeFilter) && unitTypeFilter.length > 0 && typeof filters !== 'undefined' && filters !== null && filters !== '' && typeof filters.unitType !== 'undefined' && filters.unitType !== null && filters.unitType !== "" && filters.unitType.length > 0 ?
              unitTypeFilter.map(unitTypeValue => {
                return (
                  <li key={unitTypeValue.unitTypeId}>
                    <input
                      key={unitTypeValue.unitTypeId}
                      value={unitTypeValue.unitTypeId}
                      checked={filterUnitType.some(item => item.unitTypeid === unitTypeValue.unitTypeId)}
                      className='mr-1 mb-1'
                      type="checkbox"
                      onClick={(e) => onChangeUnitType(e, unitTypeValue.unitTypeName, unitTypeValue.unitTypeId, unitTypeValue.unitMeasurement, unitTypeValue.measurementType)}
                    />
                    <label>{unitTypeValue.unitTypeName}</label>
                  </li>
                )
              })
              : ''}
          </ul>
        </div>
      </Accordion.Content>



      {/* {typeof filterUnitType !== "undefined" && filterUnitType !== null && filterUnitType !== "" && filterUnitType.length > 0 ?
        <div id='unit-dimensions-hide'>
          <Accordion.Title className='d-flex justify-content-between align-items-center'
            active={activeIndex === 3}
            index={3}
            onClick={handleClick}>
            <div className='d-flex justify-content-between align-items-center'>
              <img src="/assets/images/dimensions.png" alt="" />{t("Dimensions")}
            </div>
            <div>
              <img src="/assets/images/arrow-down.png" alt="" />
            </div>
          </Accordion.Title>

          <Accordion.Content active={activeIndex === 3}>
            <div>
              <div className='text-success text-right'>
            {selectAll ? <a onClick={() => selectAllDimension()}>{t("Select All")}</a> : ""}  | <a onClick={() => clearAllDimension()} >{t("Clear All")}</a>
          </div>
              <ul>
                {filterUnitType.map((data) => {
                  return <li key={data.unitTypeid}>
                    <input
                      key={data.unitTypeid}
                      value={data.unitTypeid}
                      checked={filterDimensions.find(item => item.unitTypeId === data.unitTypeid)}
                      className="mr-1 mb-1"
                      type="checkbox"
                      onClick={(e) => onChangeUnitMesurement(e, data.unitMeasurement, data.unitmesurementname, data.unitTypeid)}
                    />
                    {helper.displayMeasurementSize(data.unitMeasurement, data.unitmesurementname)} <span>{data.unitmesurementname}{data.measurementType}</span>
                  </li>
                })}
              </ul>


              <a href="#" className='text-success text-right d-none' onClick={handleClick}>{t("MORE")}</a>
            </div>
          </Accordion.Content>

        </div> : ""} */}

        

      {
        typeof filters !== 'undefined' && filters !== null && filters !== '' && typeof unitTypeAmenities !== 'undefined' && unitTypeAmenities !== null && unitTypeAmenities !== "" && unitTypeAmenities.length > 0 ?
          <><Accordion.Title className={`d-flex justify-content-between align-items-center`}
            active={activeIndex === 5}
            index={5}
            onClick={handleClick}
          >
            <div className={`d-flex justify-content-between align-items-center `}>
              <img src="/assets/images/amenity.png" alt="" />{t("Amenity")}
            </div>
            <div>
              <img src="/assets/images/arrow-down.png" alt="" />
            </div>
          </Accordion.Title>

            <Accordion.Content active={activeIndex === 5}>
              <div>
                <div className='text-success text-right'>
                  {selectAll ? <a onClick={() => selectAllAmenityCheckbox()} >{t("Select All")}</a> : ""} | <a onClick={() => clearAllAmenityCheckbox()}>{t("Clear All")}</a>
                </div>
                <div>
                  <ul>
                    {unitTypeAmenities.map((data) => {
                      return <li key={data.id}>
                        <input
                          key={data.id}
                          value={data.id}
                          className="mr-1 mb-1 amenties-all-checkbox"
                          checked={selectAmenities.find(item => item.id === data.id)}
                          type="checkbox"
                          onClick={(e) => sixStorageOnChangeAmenity(e, data.id, data.name)}
                        />
                        <label>{data.name}</label>
                      </li>
                    })}
                  </ul>

                </div>
                {/* </ul> */}

                <a href="/" className='text-success text-right d-none'>{t("MORE")}</a>
              </div>
            </Accordion.Content></>

          : ''
      }

      <div className='text-center my-2'>
        <button className='ui button bg-white border-success-dark-light-1 text-success fs-7 fw-400 px-5 mx-1 mb-1 mb-sm-1 px-sm-2' onClick={() => clearAllFilters()}>{t("Clear All")}</button>
        <button className='ui button bg-success-dark text-white fs-7 fw-400 px-5 mx-1 mb-1 mb-sm-1 px-sm-2' onClick={() => applyAllFiterValues()} >{t("Apply")}</button>
      </div>
    </Accordion >
  )
}

export default AccordionExampleStyled;
