export const constant = {
  CONFIG_REQUEST: 'FETCH_COFIG_REQUEST',
  CONFIG_SUCCESS: 'FETCH_COFIG_SUCCESS',
  CONFIG_FAILURE: 'FETCH_COFIG_FAILURE',

  //facilty detail[18-10-2022  -- venkatesh--  ]
  FACILITY_REQUEST: 'FETCH_FACILITY_REQUEST',
  FACILITY_SUCESS: 'FETCH_FACILITY_SUCESS',
  FACILITY_FAILURE: 'FETCH_FACILITY_FAILURE',

  //Unit Filter -- []
  UNIT_FILTER_REQUEST: 'UNIT_FILTER_REQUEST',
  UNIT_FILTER_SUCCESS: 'UNIT_FILTER_SUCCESS',
  UNIT_FILTER_FAILURE: 'UNIT_FILTER_FAILURE',
  UNIT_GETLOCATIONID: 'UNIT_GETLOCATIONID',

  //Login 

  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCESS: 'LOGIN_SUCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',

  RENT_DETAILS:'RENT_DETAILS'






};
