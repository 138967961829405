import axios from "axios";

let restUrl = process.env.REACT_APP_BASE_URL;
let AccessToken = process.env.REACT_APP_ACCESS_TOKEN;

const instance = axios.create({
    baseURL: `${restUrl}`,
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
    }
})

export default instance;