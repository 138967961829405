import React, { useState, useEffect } from "react";
import Lottie from 'react-lottie-player'
import { Button } from "semantic-ui-react";
import { useNavigate } from 'react-router-dom';
import DocumentExpiredLottie from '../../json/documentExpired.json'
import axios from "axios";
import { useTranslation } from "react-i18next";
import { async } from "q";
import { toast } from "react-toastify";



export default function DocumentExpired(props) {

    const [isLoading, setLoader] = useState(false);
    const [isButtonLoading, setButtonLoader] = useState(false);
    const [base64, setbase64] = useState();

    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    //base64 converting 
    const convertIntoBase64 = async (url) => {
        const base64 = process.env.REACT_APP_BASE64;
        const response = await axios.post(`${base64}?url=${url}`);
        setbase64(response.data);
    }

    // const triggerEsign = async (e) => {
    //     e.preventDefault();
    //     console.log("Triggered");
    //     const currentsession = JSON.parse(sessionStorage.getItem('tenantInfo'));
    //     setButtonLoader(true)
    //     await convertIntoBase64(sessionStorage.getItem("CurrentLeaseDocUrl"))
    //     const successUrl = `https://${window.location.hostname}/preBooking/viewEsignDocuments?eSigned=true`
    //     //const successUrl = window.location.hostname + '/preBooking/viewEsignDocuments' + "?eSigned=true"
    //     console.log(successUrl);
    //     const requestBody = {
    //         "event_type": "INITIATE_ESIGN_DOCUMENT_CREATION",
    //         "country_code": "NOR",
    //         "integrated_with": "signicat",
    //         "tenant_id": currentsession.userId,
    //         "initiated_by": currentsession.firstName,
    //         "request_from": "BOOKING_PORTAL",
    //         "redirect_settings": {
    //             "success_url": "https://" + window.location.host + '/preBooking/viewEsignDocuments',
    //             "abort_url": "https://" + window.location.host,
    //             "error_url": "https://" + window.location.host + '/preBooking/documentExpired'
    //         },
    //         "title": "E-Sign Document",
    //         "description": "This is an important document",
    //         "external_id": "ae7b9ca7-3839-4e0d-a070-9f14bffbbf55",
    //         "contact_details": {
    //             "email": currentsession.email
    //         },
    //         "data_to_sign": {
    //             "base64_content": `${localStorage.getItem("base64Data")}`,
    //             "file_name": "E-Sign.pdf"
    //         }
    //     }
    //     let authorizationToken = sessionStorage.getItem('authToken');
    //     // console.log("authorizationToken", authorizationToken);
    //     const config = {
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': `Bearer ${authorizationToken}`
    //         },
    //     }
    //     const eSignUrl = process.env.REACT_APP_ESIGN_URL
    //     await axios.post(eSignUrl, requestBody, config).then(response => {
    //         // console.log(response);
    //         return response
    //     }).then(result => {
    //         console.log("result", result.data.body);
    //         let redirectUrl = result.data.body.url;
    //         // console.log(redirectUrl);
    //         window.location.replace(redirectUrl);
    //         sessionStorage.setItem("bankIdDocumentId", result.data.body.document_id);
    //         sessionStorage.setItem("external_id", result.data.body.external_id);
    //         setButtonLoader(false)

    //     }).catch(err => {
    //         console.log(err);
    //     })
    // }

    const triggerEsign = async (e) => {
        e.preventDefault();
        console.log("Triggered");
        const tenantInfo = JSON.parse(sessionStorage.getItem('tenantInfo'))
        setButtonLoader(true);
        const successUrl = `https://${window.location.hostname}/preBooking/viewEsignDocuments?eSigned=true`;
        console.log(successUrl);
        const requestBody = {
          "event_type": "INITIATE_ESIGN_DOCUMENT_CREATION",
          "country_code": "NOR",
          "integrated_with": "signicat",
          "tenant_id": tenantInfo.userId,
          "initiated_by": tenantInfo.firstName,
          "request_from": "BOOKING_PORTAL",
          "redirect_settings": {
            "success_url": "https://" + window.location.host + '/preBooking/viewEsignDocuments',
            "abort_url": "https://" + window.location.host,
            "error_url": "https://" + window.location.host + '/preBooking/documentExpired'
          },
          "title": "E-Sign Document",
          "description": "This is an important document",
          "external_id": "ae7b9ca7-3839-4e0d-a070-9f14bffbbf55",
          "contact_details": {
            "email": tenantInfo.email
          },
          "data_to_sign": {
            "base64_content": `${localStorage.getItem("base64Data")}`,
            "file_name": "E-Sign.pdf"
          }
        };
        const config = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}`
          },
        };
        const eSignUrl = process.env.REACT_APP_ESIGN_URL;
        try {
          const response = await axios.post(`${eSignUrl}?value=${sessionStorage.getItem('accessToken')}`, requestBody, );
          // const response = await axios.post(eSignUrl, requestBody, config);
          console.log(response);
          if (response.status == 200 && response.data.status == 201) {
            console.log("result", response.data.body);
            let redirectUrl = response.data.body.url;
            // console.log(redirectUrl);
            window.location.replace(redirectUrl);
            sessionStorage.setItem("bankIdDocumentId", response.data.body.document_id);
            sessionStorage.setItem("external_id", response.data.body.external_id);
            setButtonLoader(false);
          } else {
            toast.error(`${t("Something went wrong")}`, {
              position: "top-right",
              autoClose: 3000,
              duration: 100,
              hideProgressBar: false,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            setButtonLoader(false);
          }
        } catch (error) {
          console.log(error);
        }
      };
    


    return (
        <div className="bg-white border-radius-15 text-center card-boxshadow w-35 mx-auto px-2 py-5 mt-5">
            <div className="success-img text-center mb-2">
                <Lottie
                    loop
                    animationData={DocumentExpiredLottie}
                    play
                    style={{ width: 250, height: 200, margin: '0 auto' }}
                />
            </div>
            <h6 className="text-danger fs-6 fw-500 mb-1">{t("The document has expired")}</h6>
            <p className="mb-2">{t("This document has expired and can no longer be signed. Please start the E-Sign process by Clicking on the link again")}</p>
            <div className="text-center">
                <Button className="ui button bg-secondary text-white fw-100 mr-2 mb-1" disabled={isButtonLoading} onClick={() => navigate('/')}>{t("Cancel")}</Button>
                <Button className="ui button bg-danger-light text-white fw-100 mb-1" disabled={isButtonLoading} loading={isButtonLoading} onClick={(e) => triggerEsign(e)}>{t("Try Again")}</Button>
            </div>
        </div>
    )
}

