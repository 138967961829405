import React, { useState } from 'react';
import { Accordion } from 'semantic-ui-react';
import { useTranslation } from "react-i18next";

const TenantDetailEmergencyContactAccordion = ({ index, name, lname, email, phone, removefunction }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const { t } = useTranslation();

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  return (
    <Accordion className='p-1'>
      <Accordion.Title
        className='d-flex justify-content-between align-items-center'
        active={activeIndex === index}
        index={index}
        onClick={handleClick}
      >
        <div className='d-flex justify-content-between align-items-center'>
          {t("Emergency Contact")} {index + 1}
        </div>
        <div>
          <img src='/assets/images/arrow-down.png' className='w-50 h-50' alt='' />
        </div>
      </Accordion.Title>
      <Accordion.Content active={activeIndex === index}>
        <table className='ui celled table  tabel-borderless '>
          <tbody>
            <tr>
              <td className='fw-600'>{t("Name")}</td>
              <td>
                {name} {lname}
              </td>
            </tr>
            <tr>
              <td className='fw-600'>{t("Email")}</td>
              <td>{email}</td>
            </tr>
            <tr>
              <td className='fw-600'>{t("Phone Number")}</td>
              <td>{phone}</td>
            </tr>
          </tbody>
        </table>
        <div className='text-center'>
          <button onClick={() => removefunction(index)} className='ui button bg-danger fs-7 fw-400 text-white px-5'>
            {t("Remove")}
          </button>
        </div>
      </Accordion.Content>
    </Accordion>
  );
}

export default TenantDetailEmergencyContactAccordion;
